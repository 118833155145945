<template>
  <div class="animated fadeIn">
    <CardTable :title="title"
               :columns="table.columns"
               :rows="table.rows"
               :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize"
               :pageNumber="serverParams.pageNumber"
               :totalPage="table.totalPage"
               :totalRecords="table.totalRecords"
               @onRowSelected="onRowSelected"
               @onPageChange="onPageChange">
      <template #action>
        <div class="card-header-actions">
          <!--calculate late payment fee for auto enabled accounts-->
          <!--<b-button size="sm" variant="primary" class="mr-2" @click="calculateLatePaymentFee">
            Calculate Late Fee
          </b-button>-->
          <b-button :to="{ name: 'DeleteAllInvoice' }" size="sm" variant="primary" class="mr-2">
            Delete Invoice
          </b-button>
          <b-button :to="{ name: 'InvoiceAdjustment' }" size="sm" variant="primary" class="mr-2">
            Adjustment
          </b-button>
          <b-button :to="{ name: 'SendAll' }" size="sm" variant="primary" class="mr-2">
            Send to all
          </b-button>
          <b-button :to="{ name: 'SaveOnePdf' }" size="sm" variant="primary" class="mr-2">
            Save all to one PDF
          </b-button>
          <b-button :to="{ name: 'InvoicesGenerate' }" size="sm" variant="primary">
            Generate Invoice Data
          </b-button>
        </div>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(period)="data">
        {{ `${parseMonth(data.item.periodMonth)}/${data.item.periodYear}` }}
      </template>
      <template #cell(usage)="data">
        {{ numberFormat(data.item.usage) }}
      </template>
      <template #cell(totalAmount)="data">
        {{ numberFormat(data.item.totalAmount) }}
      </template>
      <template #cell(outstandingBalance)="data">
        {{ numberFormat(data.item.outstandingBalance) }}
      </template>
      <template #cell(additionalDeposit)="data">
        {{ numberFormat(data.item.additionalDeposit) }}
      </template>
      <template #cell(action)="data">
        <b-button @click="handleDownload(data.item)" size="sm">Download</b-button>
        <b-button class="ml-4" @click="handleDelete(data.item)" size="sm">Delete</b-button>
      </template>
    </CardTable>
  </div>
</template>

<script>
  import { dateFormat, parseMonth, numberFormat } from "@/shared/utils";
  import { invoiceManagement as columns } from "@/shared/columns";
  import SectionFilter from "./SectionFilter.vue";

  export default {
    components: {
      SectionFilter,
    },
    data: () => ({
      title: "Invoices",
      serverParams: {
        pageNumber: 1,
        pageSize: 100,
        keyword: null,
        accountId: null,
        accountName: null,
        email: null,
        period: null,
        paymentStatus: null,
      },
      table: {
        isLoading: true,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    methods: {
      dateFormat,
      parseMonth,
      numberFormat,
      calculateLatePaymentFee() {
        const self = this;
        let _confirmText = "You are about to calculate the late payment fees for accounts with 'auto compute enabled'. Are you sure ?",
          _okText = "Yes, Submit",
          _action = "apis/post",
          _url = `/transaction/invoice/calculate-late-fee/`;
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          }).then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });
                }
              });
          });
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onRowSelected(items) {
        const self = this;
        self.$router.push({
          name: "InvoicesHistory",
          params: {
            ...self.serverParams,
            id: items[0].id,
          },
        });
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/transaction/invoice",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
      handleDownload(params) {
        const self = this;
        self.$store
          .dispatch("apis/download", {
            url: `/transaction/invoice/download/${params.id}`,
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: "File not found",
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", params.fileName);
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
      handleDelete(params) {
        const self = this;
        let _confirmText = "You are about to delete the invoice. Are you sure ?",
          _okText = "Yes, Submit",
          _action = "apis/post",
          _url = `/transaction/invoice/delete/${params.id}`;
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.serverParams,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.getAll();
                }
              });
          });
      },
    },
  };
</script>
